var Utils = Backbone.View.extend({

    initialize:function(){
        var self = this;

        this.spreadsheetID = "1QnAvCCisfM0zhtY_zOKWBY1zKo9reOD1oePY_3zR_cM";
        this.affichesData = [];
    },

    gatherData:function(callback){
        var self = this;
        this.gatherTranslations(function(){
            return callback();
        });
    },

    gatherSpreadsheetData:function(callback){
        var self = this;

        this.rootJSON = {
            "name":"root",
            "children":{}
        };

        this.parentsTree = {};

        $.get("https://spreadsheets.google.com/feeds/list/"+this.spreadsheetID+"/1/public/values?alt=json",function(data){
             _.each(data.feed.entry, function(item, index){
                console.log(item);
                self.affichesData.push({
                    image:item.gsx$image.$t,
                    mode:item.gsx$mode.$t,
                    nom:item.gsx$id.$t,
                    url:item.gsx$url.$t,
                    titre:item.gsx$titre.$t,
                    source:item.gsx$source.$t,
                    desc:item.gsx$desc.$t,
                })
             })

             return callback();
        });
    },

    trackEvent:function(eventID, params){
        console.log("trackEvent", eventID);

        if("ga" in window){
            ga(function() {
              var trackers = ga.getAll();
              var tracker = trackers[0];
              if (tracker){
                    switch(eventID){
                        case "about":
                            tracker.send("event", 'GLOBAL', 'WEBSITE', 'Modale about');
                            tracker.send("event", 'DETAILED', 'WEBSITE', 'Modale about');
                        break;
                        case "download":
                            tracker.send("event", 'GLOBAL', 'WEBSITE', "Download Affiche");
                            tracker.send("event", 'DETAILED', 'WEBSITE', "Download Affiche : "+params.afficheID);
                        break;
                        case "share":
                            tracker.send("event", 'GLOBAL', 'WEBSITE', "Share affiche " + params.networkID);
                            tracker.send("event", 'DETAILED', 'WEBSITE', "Share affiche : "+params.networkID + " - "+params.afficheID);
                        break;
                    }
                }
            });
        }
    }

});

module.exports = Utils;