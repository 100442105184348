var HeaderView = Backbone.View.extend({

    initialize:function(){
        var self = this;

    },

    render:function(){
        this.bindEvents();
    },

    bindEvents:function(){
        var self = this;
    },

});

module.exports = HeaderView;