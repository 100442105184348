var AppRouter = Backbone.Router.extend({

    routes: {
        "":"routeFromURL",
        ":param":"routeFromURL",
        ":param/":"routeFromURL",
    },

    routeFromURL:function(param){ 
        console.log("routeFromURL ??", param);
        if(param !== "" && param !== null && param !== undefined ) App.afficheRoute = param;
        App.updateFromRouter();
    },
    
    updateRoute:function(triggerize){
        this.navigate( App.selectedAffiche.url, {trigger: triggerize});
    },

});

module.exports = AppRouter;