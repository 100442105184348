var App = {
    $body:$("body"),

    init: function init() {
        this.$App = $("#App");
        this.$mainAffiches = $(".main_affiches");
        this.$AfficheModalWrapper = $("#AfficheModalWrapper");
        this.$AfficheModal = $("#AfficheModal");
        this.$afficheModalImg = $(".affichemodal_img");
        this.$AboutModalWrapper = $("#AboutModalWrapper");
        this.$AboutModal = $("#AboutModal");
        this.$AboutMobileModalWrapper = $("#AboutMobileModalWrapper");
        this.$AboutMobileModal = $("#AboutMobileModal");
        this.$CopyTextInput = $("#CopyTextInput");

        this.afficheRoute = "";
        this.selectedAffiche = {
            nom: ""
        };

        var Utils = require("utils");
        this.utils = new Utils();

        /*
        var HeaderView = require("views/headerView");
        this.headerView = new HeaderView();
        */

        var Router = require("router");
        this.router = new Router();
        Backbone.history.start({pushState: true});
    },

    updateFromRouter:function(){
        var self = this;
        this.utils.gatherSpreadsheetData(function(){
            console.log("render ?");
            self.render();
        });
    },

    render:function(){
        var self = this;
    	//this.headerView.render();
    	this.bindEvents();

        _.each(App.utils.affichesData, function(afficheData){
            var $affiche = $("<div class='affiche' data-affiche='"+afficheData.nom+"' data-mode='"+afficheData.mode+"'></div>");
            $affiche.append("<div class='affiche_img'><img src='images/affiches/"+afficheData.image+"' /></div>");
            $affiche.append("<div class='affiche_sharebar'><a class='sharebar_dlbt' download target='_blank' href='affiches/"+afficheData.image+"' data-id='"+afficheData.nom+"'><span class='dlbt_text'>Télécharger</span></a><div class='sharebar_networks'><a class='network_bt' data-network='tw' target='_blank' href='https://twitter.com/intent/tweet?text="+encodeURIComponent(afficheData.desc)+"' data-id='"+afficheData.nom+"'></a><a class='network_bt' data-network='fb' data-id='"+afficheData.nom+"'></a><a class='network_bt' data-network='lk' data-id='"+afficheData.nom+"'><span class='lk_tooltip'>Copié</span></a></div></div>");
            self.$mainAffiches.append($affiche)
        });

        if(this.afficheRoute !== "") {
            var foundAffiche = _.find(App.utils.affichesData, function(aff){
                return aff.url == self.afficheRoute;
            })
            this.displayAfficheModal(foundAffiche.nom);
        }
    },


    bindEvents:function(){
    	var self = this;

        this.$mainAffiches.on("click", ".affiche", function(){
            self.displayAfficheModal($(this).attr("data-affiche"));
        })

        /*
        this.$mainAffiches.on("click", ".affiche_sharebar .sharebar_dlbt", function (e){
            e.stopPropagation();
        });
        */

        this.$AfficheModalWrapper.on("click", function (){
            self.closeAfficheModal();
        });

        this.$AboutModalWrapper.on("click", ".modal_bg", function () {
            self.closeAboutModal();
        });

        $(".sharebar_dlbt").on("click", function(e){
            App.utils.trackEvent("download", {afficheID: $(this).attr("data-id") })
            e.stopPropagation();
        })

        $(".footer_link").on("click", function (){
            self.displayAboutModal();
            App.utils.trackEvent("about");
        });

        $("#BurgerMenuBt").on("click", function (){
            self.displayAboutMobileModal();
        });

        $("#AboutMobileModal .modal_closebt").on("click", function (){
            self.closeAboutMobileModal();
        });

        $(".network_bt[data-network='tw']").on("click", function(e){
            App.utils.trackEvent("share", {networkID: "twitter", afficheID: $(this).attr("data-id")});
            e.stopPropagation();
        });

        this.$mainAffiches.on("click", ".network_bt[data-network='fb']", function(){
            App.utils.trackEvent("share", {networkID: "facebook", afficheID: $(this).attr("data-id")});
            self.triggerFB();
            return false;
        });

        this.$mainAffiches.on("click", ".network_bt[data-network='lk']", function(){
            self.copyClipboard($(this).attr("data-id"));
            return false;
        });

        this.$AfficheModalWrapper.on("click", ".network_bt[data-network='fb']", function (){
            App.utils.trackEvent("share", {networkID: "facebook", afficheID: $(this).attr("data-id")});
            self.triggerFB();
            return false;
        });

        this.$AfficheModalWrapper.on("click", ".network_bt[data-network='lk']", function (){
            self.copyClipboard($(this).attr("data-id"), true);
            return false;
        });

        $(".sidebar_sharebar .sharebar_link[data-link='fb']").on("click", function (){
            self.triggerFB();
        })
    },

    triggerFB: function (){
        FB.ui(
            { 
                method: 'share_open_graph',
                action_type: 'og.likes',
                display:"popup",
                action_properties: JSON.stringify({
                    object: {
                        'og:url': location.href ,
                        'og:description': 'test',
                        'redirect_uri': location.href
                    }
                })
            }, 
            function(response){ console.log(response); }
        );
    },

    copyClipboard: function (afficheID, isModal) {
        var self = this;

        var foundAffiche = _.find(App.utils.affichesData, function (aff){
            return aff.nom == afficheID;
        });

        var urlToCopy = location.host + "/" + foundAffiche.url;
        this.$CopyTextInput.val(urlToCopy);
        this.$CopyTextInput[0].select();
        this.$CopyTextInput[0].setSelectionRange(0, 99999);
        document.execCommand("copy");

        console.log("copy clipboard", urlToCopy);

        var $afficheContainer = $("#AfficheModal");
        if(isModal === undefined || !isModal) {
            $afficheContainer = $(".affiche[data-affiche='"+afficheID+"']");
        }
        

        this.$currentNetworkBt = $afficheContainer.find(".network_bt[data-network='lk']")
        this.$currentNetworkBt.addClass("clipboarded");


        setTimeout(function(){
            self.$currentNetworkBt.addClass("clipboarded_after");
        }, 2000);

        setTimeout(function(){
            self.$currentNetworkBt.removeClass("clipboarded_after");
            self.$currentNetworkBt.removeClass("clipboarded");
        }, 3000);

        return false;
    },

    displayAfficheModal: function (afficheNom) {
        var self = this;

        this.selectedAffiche = _.find(App.utils.affichesData, function (aff){
            return aff.nom == afficheNom;
        });

        $("body").addClass("overflowed");
        this.$AfficheModalWrapper.addClass("domed");
        this.$AfficheModalWrapper.find(".network_bt[data-network='tw']").attr("href", "https://twitter.com/intent/tweet?text="+encodeURIComponent(this.selectedAffiche.desc));
        this.$AfficheModalWrapper.find(".network_bt[data-network='lk']").attr("data-id", this.selectedAffiche.nom);
        this.$AfficheModalWrapper.find(".network_bt").attr("data-id", this.selectedAffiche.nom);
        $(".affichemodal_title").html(this.selectedAffiche.titre);
        this.$afficheModalImg.attr("src", "images/affiches/"+this.selectedAffiche.image);
        $(".src_text").html(this.selectedAffiche.source);
        $(".sharebar_dlbt").attr("href", "affiches/"+this.selectedAffiche.image)
                            .attr("data-id", this.selectedAffiche.nom);

        setTimeout(function (){
            self.$AfficheModalWrapper.addClass("displayed");
        }, 10);

        setTimeout(function (){
            var afficheWidth = $(".affichemodal_img").width();
            $(".affichemodal_title").css("width", afficheWidth+"px");
            $(".affichemodal_bar").css("width", afficheWidth+"px");
            $(".affichemodal_src").css("margin-left", (afficheWidth/2)+"px");
            self.$AfficheModal.addClass("fadein");
        }, 200);

        this.router.updateRoute()
    },

    closeAfficheModal: function () {
        var self = this;

        $("body").removeClass("overflowed");
        self.$AfficheModalWrapper.removeClass("displayed");

        setTimeout(function (){
            self.$AfficheModal.removeClass("fadein");
            self.$AfficheModalWrapper.removeClass("domed");
        }, 600);

        this.selectedAffiche = {
            nom: ""
        };

        this.router.updateRoute()
    },

    displayAboutModal: function () {
        var self = this;

        $("body").addClass("overflowed");
        this.$AboutModalWrapper.addClass("domed");

        setTimeout(function (){
            self.$AboutModalWrapper.addClass("displayed");
        }, 10);

        setTimeout(function (){
            self.$AboutModal.addClass("fadein");
        }, 100);
    },

    closeAboutModal: function () {
        var self = this;

        $("body").removeClass("overflowed");
        self.$AboutModalWrapper.removeClass("displayed");

        setTimeout(function (){
            self.$AboutModal.removeClass("fadein");
            self.$AboutModalWrapper.removeClass("domed");
        }, 600);
    },

    displayAboutMobileModal: function () {
        var self = this;

        $("body").addClass("overflowed");
        this.$AboutMobileModalWrapper.addClass("domed");

        setTimeout(function (){
            self.$AboutMobileModalWrapper.addClass("displayed");
        }, 10);

        setTimeout(function (){
            self.$AboutMobileModal.addClass("fadein");
        }, 100);
    },

    closeAboutMobileModal: function () {
        var self = this;

        $("body").removeClass("overflowed");
        self.$AboutMobileModalWrapper.removeClass("displayed");

        setTimeout(function (){
            self.$AboutMobileModal.removeClass("fadein");
            self.$AboutMobileModalWrapper.removeClass("domed");
        }, 600);
    },

};

module.exports = App;
window.App = App;